<template>
    <div class="sidebar-menu open">
        <nav class="desktop-menu">
            <ul class="menu">
                <li class="menu-item current-menu-item"><router-link to="/"><i class="fas fa-home"></i><span>الصفحة الرئيسية </span></router-link></li>
                <li class="menu-item"><router-link to="/Courses"><i class="fas fa-coffee"></i><span>الكورسات</span></router-link></li>
                <li class="menu-item"><router-link to="/Teachers"><i class="fa fa-user"></i><span>الاساتذة</span></router-link></li>
                <li class="menu-item pointer" v-if="showHeader"><router-link to="/Unsubscribe"><i class="fas fa-tag"></i><span>إشتراك </span></router-link></li>
                <li class="menu-item pointer" v-else><a @click="gotoSpay()"><i class="fas fa-tag"></i><span>إشتراك </span></a></li>
            </ul>
        </nav>
    </div>
</template>

<script>
export default {
 name:'AppSideBar',
 data() {
     return {
       showHeader: false,
     }
    },
 mounted() {
     if (this.$cookie.isCookieAvailable("msisdn")) {
           this.showHeader =  true;
        } else {
           this.showHeader = false;
        }
  },
  methods: {
      gotoSpay() {
      // window.open("http://sudanipay.com/subscription/nashoof-sd/nashoof-sd", "_self");
    },
  }
}
</script>

<style>
.pointer {
  cursor: pointer;
}
</style>